import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import { useState } from "react"
import { toast } from "react-toastify"

const optionGroup = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
  { label: "Contract", value: "Contract" },
]

const User = ({ modal, setModal, toggle }) => {
  const token = localStorage.getItem("Admintoken")
  const apiURL = process.env.REACT_APP_BACKEND_URL
  const [jobData, setJobData] = useState({
    title: "",
    location: "",
    description: "",
    jobType: null,
    experience: "",
  })

  document.title = "Form | RadarSoft Admin Dashboard"

  const handleChange = e => {
    const { name, value } = e.target
    setJobData({ ...jobData, [name]: value })
  }

  const handleSelectGroup = selectedOption => {
    setJobData({ ...jobData, jobType: selectedOption })
  }

  const handleFormSubmission = async () => {
    console.log("function called.", jobData)
    try {
      const response = await fetch(`${apiURL}/api/admin/job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(jobData),
      })
      if (!response.ok) {
        setModal(!modal)
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setModal(!modal)
      toast("Job Added successfully!")
      console.log("File uploaded successfully:", data)
    } catch (error) {
      console.error("Error uploading file:", error)
    }
  }

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle} className="w-100">
          <ModalHeader>Add Jobs</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Job Title</Label>
                  <Input
                    name="title"
                    placeholder="Enter job title"
                    type="text"
                    value={jobData.title}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label>Location</Label>
                  <Input
                    name="location"
                    type="text"
                    placeholder="Enter job location"
                    value={jobData.location}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Description</Label>
                  <Input
                    name="description"
                    placeholder="Job description"
                    type="textarea"
                    value={jobData.description}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Job Type</Label>
                  <Select
                    value={jobData.jobType}
                    onChange={handleSelectGroup}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label>Experience</Label>
                  <Input
                    name="experience"
                    type="text"
                    placeholder="Enter experience"
                    value={jobData.experience}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex flex-wrap gap-2">
              <Button
                type="submit"
                color="primary"
                onClick={handleFormSubmission}
              >
                Add
              </Button>{" "}
              <Button type="reset" color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

export default User
