import { Button, Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import Status from "components/Common/model/Status"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ThreeDots } from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"

const Careers = () => {
  const [modal, setModal] = useState(false)
  const [updateID, setUpdateID] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_BACKEND_URL
  const [visaList, setVisaList] = useState([])

  const toggle = (itemID = null) => {
    setModal(!modal)
    setUpdateID(itemID)
  }

  const fetchCareerlist = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin/career`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        setVisaList(res.data)
        console.log("res careers list.................................", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  const handleStatus = async id => {
    console.log("status updated ID", id)
    try {
      const apiUrl = `${apiURL}/api/admin/career/${id}`
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        setModal(!modal)
        throw new Error("Network response was not ok")
      }
      const data = await response.json()
      setModal(!modal)
      toast("User Deleted successfully!")
      fetchCareerlist()
      console.log("Status updated successfully:", data)
    } catch (error) {
      console.error("Error User Deleted :", error)
    }
  }

  const download = e => {
    e.preventDefault()

    const url = e.target.href
    console.log(url)

    fetch(url, {
      method: "GET",
      headers: {
        // You can add custom headers here if needed
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const blob = new Blob([buffer])
        const link = document.createElement("a")
        const fileName = url.split("/").pop() // Extract file name from URL
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName // Use the extracted file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up
        window.URL.revokeObjectURL(link.href) // Release the object URL
      })
      .catch(err => {
        console.error("Download failed:", err)
      })
  }

  useEffect(() => {
    fetchCareerlist()
  }, [])

  const rows = visaList?.map((user, i) => ({
    ...user,
    ID: i + 1,
    fullName: user?.name,
    email: user?.email,
    createdAt: user.createdAt?.substring(0, 10),
    resume: (
      <a href={user?.resume} download onClick={e => download(e)}>
        Download
      </a>
    ),
    view: (
      <a href={user?.resume} target="_blank" rel="noopener noreferrer">
        View Resume
      </a>
    ),
    actions: (
      <Button
        type="submit"
        color="success"
        style={{ fontSize: "12px" }}
        onClick={() => toggle(user?._id)}
      >
        Delete
      </Button>
    ),
    jobTitle: user?.jobTitle,
    currentCTC: user?.currentCTC,
    expectedCTC: user?.expectedCTC
  }))

  //datatables here.
  const data = {
    columns: [
      {
        label: "#ID",
        field: "ID",
        sort: "asc",
        width: 20,
      },
      {
        label: "Full Name",
        field: "fullName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Job Title",
        field: "jobTitle",
        sort: "asc",
        width: 100,
      },
      {
        label: "Current CTC",
        field: "currentCTC",
        sort: "asc",
        width: 100,
      },
      {
        label: "Expected CTC",
        field: "expectedCTC",
        sort: "asc",
        width: 100,
      },
      {
        label: "Resume",
        field: "resume",
        width: 100,
      },
      {
        label: "View",
        field: "view",
        width: 100,
      },
      {
        label: "Create Date",
        field: "createdAt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "actions",
        // sort:"asc",
        width: 150,
      },
    ],
    rows,
  }
  document.title = "Careers List | Admin & Dashboard"
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="RadarSoft Admin"
            title="Careers List"
            breadcrumbItem="Careers List"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <MDBDataTable responsive striped bordered data={data} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {
        <Status
          modal={modal}
          toggle={toggle}
          id={updateID}
          handleStatus={handleStatus}
        />
      }
    </>
  )
}

export default Careers
