import { Button, Card, CardBody, Col, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import Status from "components/Common/model/Status"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { ThreeDots } from "react-loader-spinner"
import { MDBDataTable } from "mdbreact"

const Appoinment = () => {
    const [modal, setModal] = useState(false)
    const [updateID, setUpdateID] = useState(null)
    const [selectedGroup, setselectedGroup] = useState(null)
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem("Admintoken")
    const navigate = useNavigate()
    const apiURL = process.env.REACT_APP_BACKEND_URL
    const [visaList, setVisaList] = useState([])

    const toggle = (itemID = null) => {
        setModal(!modal)
        setUpdateID(itemID)
    }

    const fetchAppoinmentlist = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${apiURL}/api/admin/appoinment`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const res = await response.json()
            if (res) {
                if (res.message == "Token verification failed") {
                    navigate("/login")
                }
                setVisaList(res.data)
                console.log("res Appoinment list.................................", res)
            }
        } catch (error) {
            console.log("error in fetchUsers", error)
        } finally {
            setLoading(false) // Set loading to false after fetching
        }
    }

    const handleStatus = async id => {
        console.log("status updated ID", id)
        try {
            const apiUrl = `${apiURL}/api/admin/appoinment/${id}`
            const response = await fetch(apiUrl, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            console.log("Status updated successfully:", response)
            if (!response.ok) {
                setModal(!modal)
                throw new Error("Network response was not ok")
            }
            const data = await response.json()
            setModal(!modal)
            toast("Appoinment Deleted successfully!")
            fetchAppoinmentlist()
            console.log("Status updated successfully:", data)
        } catch (error) {
            console.error("Error updating status:", error)
        }
    }

    useEffect(() => {
        fetchAppoinmentlist()
    }, [])

    const rows = visaList?.map((user, i) => ({
        ...user,
        ID: i + 1,
        fullName:
            //   <p
            //     style={{ cursor: "pointer", color: "blue" }}
            //     onClick={() => handleDetails(user._id)}
            //   >
            user?.fullName,
        //   </p>
        email: user?.email,
        createdAt: user.createdAt?.substring(0, 10),
        subject: user?.subject,
        actions: (
            <Button
                type="submit"
                color="success"
                style={{ fontSize: "12px" }}
                onClick={() => toggle(user?._id)}
            >
                Delete
            </Button>
        ),
        MobileNumber: user?.phoneNumber,
    }))

    //datatables here.
    const data = {
        columns: [
            {
                label: "#ID",
                field: "ID",
                sort: "asc",
                width: 20,
            },
            {
                label: "Full Name",
                field: "fullName",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Subject",
                field: "subject",
                sort: "asc",
                width: 100,
            },
            {
                label: "Mobile No",
                field: "MobileNumber",
                sort: "asc",
                width: 100,
            },
            {
                label: "Create Date",
                field: "createdAt",
                sort: "asc",
                width: 100,
            },
            {
                label: "Action",
                field: "actions",
                // sort:"asc",
                width: 150,
            },
        ],
        rows,
    }

    document.title = "Appoinment | Admin & Dashboard"

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        maintitle="RadarSoft Admin"
                        title="Appoinment List"
                        breadcrumbItem="Appoinment List"
                    />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        {loading ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "20px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <ThreeDots
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    color="#6f42c1"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>
                                        ) : (
                                            <MDBDataTable responsive striped bordered data={data} />
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {
                <Status
                    modal={modal}
                    toggle={toggle}
                    id={updateID}
                    handleStatus={handleStatus}
                />
            }
        </>
    )
}

export default Appoinment
